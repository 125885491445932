class Planit

  # ------------------------------------------ Refs

  @containerClass:        'planit-container'
  @markerContainerClass:  'planit-markers-container'
  @markerClass:           'planit-marker'
  @markerContentClass:    'planit-marker-content'

  # ------------------------------------------ Default Options

  new: (@options = {}) ->
    # Set Options
    if @options.container
      @options.container = $('#planit') 
    else
      @options.container = $("##{@options.container}")

    # Initialize Container
    @options.container.addClass('planit-container')
    @options.container.append """
      <div class="#{Planit.markerContainerClass}"></div>
        """

    # Refs
    @container = @options.container
    @markersContainer = @container.find(".#{Planit.markerContainerClass}").first()

    # Add Background Image (if necessary)
    if @options.backgroundImage
      @container.append("""<img src="#{@options.backgroundImage}">""")
      @markersContainer.css
        backgroundImage: "url('#{@options.backgroundImage}')"
      $(window).load =>
        @container.css
          height: @container.find('img').first().height()
        @container.find('img').first().remove()

    # Add Markers (if necessary)
    if @options.markers
      $(window).load () =>
        @addMarker(marker) for marker in @options.markers

    # Bind Document Events
    new Planit.Plan.Events
      container: @container
      planit: @

    # Return this Planit object
    @

  # ------------------------------------------ Add A Marker

  addMarker: (options) =>
    options.container = @container
    new Planit.Marker.Creator(options)

  # ------------------------------------------ Get All Markers

  # getAllMarkers: () =>
  #   console.log @
  #   @plan.getAllMarkers()

  # ------------------------------------------ Event Callbacks

  dragEnd: (event, marker) =>
    if @options.dragEnd
      @options.dragEnd(event, marker)

  # ------------------------------------------ Class Methods

  @randomString: (length = 16) ->
    str = Math.random().toString(36).slice(2) 
    str = str + Math.random().toString(36).slice(2)
    str.substring(0, length - 1)

# set this class to a global `planit` variable
window.planit = new Planit

class Planit.Plan

  # ------------------------------------------ Setup

  constructor: (@container) ->
    @markersContainer = @container.find(".#{Planit.markerContainerClass}").first()

  # ------------------------------------------ Get All Markers

  getAllMarkers: () =>
    markers = []
    for marker in @markersContainer.find('.planit-marker')
      m = $(marker)
      marker =
        coords: [m.position().left, m.position().top]
      info = m.find('.planit-infobox')
      if info.length > 0
        marker.infobox = info.html()
      markers.push(marker)
    markers

class Planit.Plan.Events

  # ------------------------------------------ Setup

  constructor: (@options) ->

    # default options
    @container = @options.container
    @markersContainer = @container.find(".#{Planit.markerContainerClass}")

    # bind draggable events
    $(document).on('mousemove', @mousemove)
    $(document).on('mouseup', @mouseup)

  # ------------------------------------------ Refs

  markers: =>
    @markersContainer.find('.planit-marker')

  draggingMarker: =>
    @markersContainer.find('.planit-marker.is-dragging')

  lastMarker: =>
    @markers().last()

  # ------------------------------------------ Events

  mouseup: (e) =>
    if $(e.target).hasClass('planit-marker-content')
      marker = $(e.target).closest('.planit-marker')
      $("##{marker.attr('data-infobox')}").addClass('active')
    marker = @markersContainer.find('.is-dragging').first()
    if @draggingMarker().length > 0
      m = new Planit.Marker(@container, marker.attr('data-marker'))
      @options.planit.dragEnd(e, m)
      @draggingMarker().removeClass('is-dragging')

  mousemove: (e) =>
    markers = @markersContainer.find('.planit-marker.is-dragging')
    if markers.length > 0

      # only use first marker in case there are more than
      # one dragging
      # 
      marker = markers.first()

      # calculate positions
      # 
      mouseLeft     = e.pageX - @container.offset().left
      mouseTop      = e.pageY - @container.offset().top
      planRight     = @container.width()
      planBottom    = @container.height()
      markerLeft    = mouseLeft - (marker.outerWidth() / 2)
      markerTop     = mouseTop - (marker.outerHeight() / 2)
      markerRight   = mouseLeft + (marker.outerWidth() / 2)
      markerBottom  = mouseTop + (marker.outerHeight() / 2)
      markerWidth   = marker.outerWidth()
      markerHeight  = marker.outerHeight()

      # find the left position of the marker based on
      # position of the mouse relative to the plan
      # 
      if markerLeft <= 0
        markerX = 0
      else if markerRight < planRight
        markerX = markerLeft
      else
        markerX = planRight - markerWidth

      # find the left position of the marker based on
      # position of the mouse relative to the plan
      # 
      if markerTop <= 0
        markerY = 0
      else if markerBottom < planBottom
        markerY = markerTop
      else
        markerY = planBottom - markerHeight

      # set the position of the marker
      # 
      marker.css
        left: markerX
        top: markerY

class Planit.Marker

  constructor: (@container, id) ->

    # Set Options
    @markersContainer = @container.find(".#{Planit.markerContainerClass}")

    # Find Marker
    @marker = @markersContainer.find(
      ".#{Planit.markerClass}[data-marker='#{id}']"
    ).first()

    # Return this
    @

  # ------------------------------------------ Marker Calcs

  position: =>
    # console.log @marker.outerWidth() / @container.width()
    xPx = @marker.position().left + (@marker.outerWidth() / 2)
    yPx = @marker.position().top + (@marker.outerHeight() / 2)
    xPc = xPx / @container.width()
    yPc = yPx / @container.height()
    [xPc, yPc]

class Planit.Marker.Events

  constructor: (@options) ->

    # Set Options
    @container = @options.container
    @markersContainer = @container.find(".#{Planit.markerContainerClass}")

    # Find Marker
    @marker = @markersContainer.find(
      ".#{Planit.markerClass}[data-marker='#{@options.id}']"
    ).first()

    # Draggable
    if @options.draggable
      @lastMarker().addClass('draggable')
      @lastMarker().on 'mousedown', (e) =>
        marker = $(e.target).closest('.planit-marker')
        marker.addClass('is-dragging')
        infoboxID = $(e.target).closest('.planit-marker').attr('data-infobox')
        $("##{infoboxID}").removeClass('active')

    # Infobox
    if @options.infobox
      id = Planit.randomString(16)
      @lastMarker().find('.planit-marker-content').append """
        <div class="planit-infobox" id="info-#{id}">#{@options.infobox}</div>
          """
      @lastMarker().attr('data-infobox', "info-#{id}")
      infobox = $("##{@lastMarker().attr('data-infobox')}")
      infobox.css
        left: -(infobox.width() / 2)
        bottom: infobox.outerHeight() + 5
      @lastMarker().on 'mouseleave', (e) =>
        marker = $(e.target).closest('.planit-marker')
        infobox = $("##{marker.attr('data-infobox')}")
        infobox.removeClass('active')
      @lastMarker().on 'mouseover', (e) =>
        marker = $(e.target).closest('.planit-marker')
        infobox = $("##{marker.attr('data-infobox')}")
        if marker.hasClass('is-dragging') || @draggingMarker().length > 0
          infobox.removeClass('active')
        else
          infobox.addClass('active')

  markers: ->
    @markersContainer.find('.planit-marker')

  draggingMarker: ->
    @markersContainer.find('.planit-marker.is-dragging')

  lastMarker: ->
    @markers().last()

class Planit.Marker.Creator

  constructor: (@options) ->
    # Set Options
    @container = @options.container
    @markersContainer = @container.find(".#{Planit.markerContainerClass}").first()
    unless @options.id
      @options.id = Planit.randomString(20)

    # Add Marker
    @markersContainer.append(
      $('<div><div class="planit-marker-content"></div></div>')
        .addClass('planit-marker')
        .attr('data-marker', @options.id)
        .css
          left: "#{@options.coords[0]}%"
          top: "#{@options.coords[1]}%"
    )

    # Bind Events (in a separate class)
    new Planit.Marker.Events(@options)

    # Return a new instance of this marker
    new Planit.Marker(@container, @options.id)
